exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avisode-privacidad-js": () => import("./../../../src/pages/avisodePrivacidad.js" /* webpackChunkName: "component---src-pages-avisode-privacidad-js" */),
  "component---src-pages-ayuda-js": () => import("./../../../src/pages/ayuda.js" /* webpackChunkName: "component---src-pages-ayuda-js" */),
  "component---src-pages-comunicacion-js": () => import("./../../../src/pages/comunicacion.js" /* webpackChunkName: "component---src-pages-comunicacion-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-directorio-js": () => import("./../../../src/pages/directorio.js" /* webpackChunkName: "component---src-pages-directorio-js" */),
  "component---src-pages-fontlinks-js": () => import("./../../../src/pages/fontlinks.js" /* webpackChunkName: "component---src-pages-fontlinks-js" */),
  "component---src-pages-identidad-js": () => import("./../../../src/pages/identidad.js" /* webpackChunkName: "component---src-pages-identidad-js" */),
  "component---src-pages-impuesto-js": () => import("./../../../src/pages/impuesto.js" /* webpackChunkName: "component---src-pages-impuesto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metodospago-js": () => import("./../../../src/pages/metodospago.js" /* webpackChunkName: "component---src-pages-metodospago-js" */),
  "component---src-pages-pago-licencias-js": () => import("./../../../src/pages/pagoLicencias.js" /* webpackChunkName: "component---src-pages-pago-licencias-js" */),
  "component---src-pages-selector-actas-js": () => import("./../../../src/pages/SelectorActas.js" /* webpackChunkName: "component---src-pages-selector-actas-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-terminosycondiciones-js": () => import("./../../../src/pages/términosycondiciones.js" /* webpackChunkName: "component---src-pages-terminosycondiciones-js" */),
  "component---src-pages-territorio-vivienda-js": () => import("./../../../src/pages/territorioVivienda.js" /* webpackChunkName: "component---src-pages-territorio-vivienda-js" */),
  "component---src-pages-tramitesyservicios-js": () => import("./../../../src/pages/tramitesyservicios.js" /* webpackChunkName: "component---src-pages-tramitesyservicios-js" */),
  "component---src-pages-vehicular-js": () => import("./../../../src/pages/vehicular.js" /* webpackChunkName: "component---src-pages-vehicular-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdownTemplate.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */)
}

